import React from "react";

import { PageContainer, BodyText, InlineLink, List } from "../recipes";

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import ContactLink from "../components/ContactLink"

const Page = () => (
  <Layout>
    <SEO title="No fun" />
    <PageContainer>
      <PageContainer.Title text="nofun.title" />
      <BodyText>
        It's not your fault you don't like cake (probably). But, you too can get something out of this, the steps are easy.
      </BodyText>
      <List>
        <List.Item><List.Text>Pick a loved one.</List.Text></List.Item>
        <List.Item><List.Text>Find their favourite cake <InlineLink to="/flavours">flavour</InlineLink>.</List.Text></List.Item>
        <List.Item><List.Text>Find out if they prefer <InlineLink to="/cakes">cake</InlineLink> or <InlineLink to="/cupcakes">cupcakes</InlineLink>.</List.Text></List.Item>
        <List.Item><List.Text><InlineLink to="/contact">Ask me</InlineLink> to make it.</List.Text></List.Item>
        <List.Item><List.Text>Bask in the glow that you made someones day.</List.Text></List.Item>
      </List>
      <BodyText>
        How easy?
      </BodyText>
      <ContactLink>Wow, that is easy</ContactLink>
    </PageContainer>
  </Layout>
);

export default Page;
